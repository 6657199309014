import {applyMiddleware, compose, createStore} from 'redux';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk'
import rootReducer from "../reducers";

export default function configureStore(preloadedState = {}){
    const middlewareEnhancer = composeWithDevTools(
        applyMiddleware(thunkMiddleware),
    );

    const enhancers = [middlewareEnhancer];
    const composedEnhancers = compose(...enhancers);

    return createStore(rootReducer, preloadedState, composedEnhancers);
}
