import {Redirect} from "react-router-dom";
import DashboardLayout from "../layouts/dasboardLayout";
import React, {lazy} from "react";
import AuthLayout from "../layouts/authLayout";

// const secureRoute = (component, token) => {
//     return !token
//         ? <Redirect to="/auth/login"/>
//         : component
// }
// const getRoutes = (token)=> [
const getRoutes = ()=> [
        {
        path: "/",
        exact: true,
        component: () => <Redirect to="/home"/>
    },
    {
        path: "/errors",
        component: (props) => <DashboardLayout {...props} />,
        routes: [
            {
                path: '/errors/401',
                exact: true,
                component: lazy(() => import('../views/Errors/Error401'))
            },
            {
                path: '/errors/404',
                exact: true,
                component: lazy(() => import('../views/Errors/Error404'))
            },
            {
                path: '/errors/500',
                exact: true,
                component: lazy(() => import('../views/Errors/Error500'))
            }
        ]
    },
    {
        path: "/auth",
        component: (props) => <AuthLayout {...props} />,
        routes: [
            // {
            //     path: '/auth/login',
            //     exact: true,
            //     component: lazy(() => import('../views/Auth/Login'))
            // },
            // {
            //     path: '/auth/register',
            //     exact: true,
            //     component: lazy(() => import('../views/Auth/Register'))
            // },
            // {
            //     component: () => <Redirect to="/errors/404"/>
            // }
        ]
    },
    {
        route: '*',
        component: (props) => <DashboardLayout {...props} />,
        routes: [
            {
                path: "/home",
                component: lazy(() => import('../views/home/home'))
            },{
                path: "/contact",
                component: lazy(() => import('../views/contact/contact'))
            },{
                path: "/products",
                component: lazy(() => import('../views/products/products'))
            },{
                path: "/services",
                component: lazy(() => import('../views/services/services'))
            },{
                path: "/about",
                component: lazy(() => import('../views/about/about'))
            },{
                path: "/ourspace",
                component: lazy(() => import('../views/xwros/xwros'))
            },
            {
                component: () => <Redirect to="/errors/404"/>
            }
        ]
    }
];

export {
    getRoutes
}
