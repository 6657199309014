import Spin from 'antd/lib/spin';
import React, { Suspense } from 'react';
import { renderRoutes} from "react-router-config";
import { LoadingOutlined, FacebookOutlined, InstagramOutlined, GoogleOutlined } from "@ant-design/icons"
import { Layout } from 'antd';
import Menu from './menu';

function Index(props){
    const {route} = props;

    return (
        <Layout style={{minHeight: "100vh"}}>
            <Layout.Header className="site-layout-background" style={{padding: 0, width: '100vw', minWidth:"1300px", zIndex: 99999, position: 'fixed', top: 0}}>
                <Menu/>
            </Layout.Header>
            <Layout.Content className="site-layout-background"
                            style={{margin: '24px 16px',
                                    padding: 24,
                                    minHeight: 280,
                                    }}
            >
                <Suspense fallback={<Spin indicator={<LoadingOutlined style={{fontSize: 24}} spin/>}/>}>
                    {renderRoutes(route.routes)}
                </Suspense>
            </Layout.Content>
            <Layout.Footer style={{backgroundColor:"#2d3e50", color:"rgb(240, 242, 245)"}}>
                <div className="contact_info">
                    Οδός: Γεωργίου Σταύρου 14, Θεσσαλονίκη<br/>
                    Tηλ.: 2310 270 110<br/><br/>
                </div>
                <div className="social">
                <a target="_blank" href='https://www.facebook.com/mpanasa.androniki'><FacebookOutlined style={{fontSize:40, color: "#D4AF37"}}/></a> 
                <a target="_blank" href="https://www.instagram.com/desirez.de.beaute.institute/"><InstagramOutlined style={{fontSize:40, marginLeft: 10, color: "#D4AF37"}}/></a>
                <a target="_blank" href='https://www.google.com/search?q=desirez+de+beaute&oq=desirez+&aqs=chrome.0.69i59j46i39i175i199j69i57j69i60j69i61l3j69i60.3108j0j7&sourceid=chrome&ie=UTF-8'><GoogleOutlined style={{fontSize:40, marginLeft: 10, color: "#D4AF37"}}/></a>
                </div>
            </Layout.Footer>
        </Layout>
    );
}

export default Index;
