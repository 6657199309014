const INIT_STATE = {
    error: '',
    loading: false,
};

const commonReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case 'FETCH_START': {
            return {
                ...state,
                error: '',
                loading: true,
            };
        }
        case 'FETCH_SUCCESS': {
            return {
                ...state,
                error: '',
                loading: false,
            };
        }
        case 'FETCH_ERROR': {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        }
        case 'FETCH_RESET': {
            return INIT_STATE;
        }
        default:
            return state;
    }
};

export default commonReducer;
